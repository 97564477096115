.select_graph {
  display: block;
  margin: 1vw 0vw 0.5vw 10vw;
}

.hidden {
  display: none;
}

.canvas {
  display: block;
  margin: auto;
}

.graph {
  display: block;
}
