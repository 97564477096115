.fourier-color-box0 {
  border: solid 1px #2eb6ea;
}

.fourier-color-box1 {
  border: solid 1px #3aaa35;
}

.fourier-color-box2 {
  border: solid 1px #ff4141;
}
