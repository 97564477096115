.waveguide-amplitude-main {
  display: block;
  margin-top: 10px;
}

.waveguide-amplitude-block {
  display: inline-block;
}

.waveguide-amplitude-text {
  font-size: 70%;
}
