.SliderEdit {
  margin: auto;
  text-align: left;
  font-size: 90%;
}

.SliderEditUnit {
  height: 1.5em;
  margin: auto;
  text-align: left;
  position: relative;
}

.slider {
  position: absolute;
  width: 28%;
  left: 35%;
}

.variableName {
  position: absolute;
  right: 66%;
}

.value {
  position: absolute;
  left: 65%;
  width: 30%;
}

.unit {
  display: "inline";
  position: absolute;
  left: 78%;
}

.polarity {
  text-align: center;
}

@media screen and (max-width: 900px) {
  .variableName {
    font-size: 80%;
  }
  .value {
    font-size: 80%;
  }
  .unit {
    font-size: 80%;
  }
  .polarity {
    font-size: 80%;
  }
}
