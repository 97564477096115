.graph-properties {
  display: block;
  margin: 1vw 0vw 1vw 0vw;
  font-size: 80%;
}

.graph-property {
  display: inline;
  margin-left: 1vw;
}

.hidden {
  display: none;
}

.canvas {
  display: block;
  margin: auto;
}

.graph {
  display: block;
}

.sliders {
  width: 80%;
  margin: auto;
}

.border-div {
  border-style: solid;
  border-width: 1px;
  border-color: #ccc;
  margin-bottom: 10px;
}

.chart-div {
  position: relative;
  top: 0px;
  margin-bottom: 5px;
}

.chart-div-hidden {
  margin-bottom: 25px;
}

.chart-hide-button {
  position: absolute;
  right: 0px;
  font-size: 70%;
  color: #131a4f;
  background-color: #d0dae9;
  padding: 3px;
  border-bottom: solid;
  border-left: solid;
  border-right: solid;
  border-width: 1px;
  border-color: #ccc;
}
