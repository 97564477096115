.app {
  font-family: Arial, Helvetica, sans-serif;
}

.my-div {
  text-align: center;
  border: 1px solid black;
  padding: 20px;
  margin: 20px 80px 20px 280px;
  box-sizing: border-box;
  background-color: #fff;
}

.one-graph {
  max-width: 500px;
  margin: auto;
}

.two-graphs {
  float: left;
  width: 45%;
  padding-right: 5%;
}

.clr {
  clear: both;
}

.slider-block {
  width: 80%;
  margin: auto;
  margin-top: 10px;
  padding: 10px;
}

@media only screen and (max-width: 1024px) {
  .two-graphs {
    float: none;
    width: 100%;
    max-width: 500px;
    margin: auto;
  }
}
