.title-info-advanced-loudspeaker {
  margin: 0;
  padding: 10px;
  font-size: 80%;
}

.text-info-advanced-loudspeaker {
  margin: auto;
  width: 60%;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 80%;
}
