.title-info-blocked-impedance {
  margin: 0;
  padding: 10px;
  font-size: 80%;
}

.text-info-blocked-impedance {
  margin: auto;
  width: 60%;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 80%;
}

.image-viewer-blocked-impedance {
  margin: auto;
  width: 25%;
}
