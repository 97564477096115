.hidediv-div {
  border-style: solid;
  border-width: 1px;
  border-color: #ccc;
  margin-bottom: 10px;
  position: relative;
}

.hidediv-div-hidden {
  margin-bottom: 25px;
}

.hidediv-button {
  position: absolute;
  right: 0px;
  font-size: 70%;
  color: #131a4f;
  background-color: #d0dae9;
  padding: 3px;
  border-bottom: solid;
  border-left: solid;
  border-right: solid;
  border-width: 1px;
  border-color: #ccc;
}
