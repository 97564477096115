.navbar {
  width: 250px;
  position: fixed;
  top: 20px;
  left: 10px;
  overflow-x: hidden;
  padding: 8px 0px;
  margin: 0px;
  height: 50%;
  border: 1px solid;
}

.navbar__link {
  cursor: pointer;
  text-decoration: none;
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  color: rgb(19, 26, 79);
  display: block;
}

.navbar__link:hover {
  color: black;
}

.navbar__link--active {
  background-color: white;
  transition: border-bottom 0.5s ease-in-out;
}
